import React, { useCallback, useState } from 'react';
import { get } from 'lodash';
import { Col, Form, Popover, Row } from 'antd';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

import ReportTab from './report-tab';

import { IReportTab } from '../../types/models/reports';
import TabNavigation from '../../components/common/tab-navigation';
import { FormItem, Select } from '../../components/forms';
import { Button } from '../../components';
import PageHeader from '../../components/common/page-header';
import AdminContent from '../../components/admin/common/admin-content';
import { AdminContentLayoutType } from '../../types/layout';
import theme from '../../theme';

const CustomTabWrapper = styled.div`
  & .ant-row {
    margin-right: 0;
    margin-left: 0;
    display: inline-flex;
    width: 100%;
    flex-wrap: wrap;
    padding-left: 16px;
  }

  & > .ant-row-flex {
    min-width: auto;
    min-height: 60vh;
  }

  & .ant-table-wrapper {
    max-width: 100%;
  }
`;

const StyledCol = styled(Col)`
  min-height: 115px;
`;

const StyledSelect = styled.div`
  & .ant-select-selection--multiple .ant-select-selection__choice__remove {
    right: 0;
    top: 6px;
  }
`;

const Container = styled.div`
  background-color: ${theme.colorsLightGrey};
  padding: 16px 24px;
  align-items: center;
  margin-bottom: 30px;
`;

interface ICmsReportDetailsProps {
  data: any;
}

export default function CmsReportDetails({ data }: ICmsReportDetailsProps) {
  const filteredAdvisoryIds = data.allDirectusTeamMember.nodes.map((node: any) => node.advisory_council.name);
  const advisoryCouncils = data.allDirectusAdvisoryCouncilsSection.nodes.reduce((acc: any, node: any) => {
    return filteredAdvisoryIds.includes(node.name)
      ? [
          ...acc,
          {
            id: node.name,
            name: node.value,
          },
        ]
      : [...acc];
  }, []);
  const advisoryCouncilsMap = advisoryCouncils.reduce((acc: any, val: any) => {
    acc[val.id] = val.name;
    return acc;
  }, {});

  const fullTableData = data.allDirectusTeamMember.nodes.map((node: any, i: number) => ({
    id: i,
    name: `${node.team_first_name} ${node.team_last_name}`,
    councilAssociatedWith: advisoryCouncilsMap[node.advisory_council.name],
    councilAssociatedWithId: node.advisory_council.name,
    company: node.company,
    jobTitle: node.team_job_title,
    bio: node.team_bio,
    headshot: node.team_photo && node.team_photo.data && node.team_photo.data.full_url,
  }));

  const [selectedCouncilAssociatedWith, setSelectedCouncilAssociatedWith] = useState('');
  const [tableData, setTableData] = useState(fullTableData);

  const getTabDataSource = (source: IReportTab[]) => {
    return source.map((tab: IReportTab, index: number) => ({
      label: tab.title,
      value: index.toString(),
    }));
  };

  const selectOnChange = ({ value }: { value: string }) => {
    console.log(value);
    setSelectedCouncilAssociatedWith(value);
  };

  const clearFilters = () => {
    setSelectedCouncilAssociatedWith('');
    setTableData(fullTableData);
  };

  const applyFilters = () => {
    setTableData(
      selectedCouncilAssociatedWith
        ? fullTableData.filter((member: any) => member.councilAssociatedWithId === selectedCouncilAssociatedWith)
        : fullTableData
    );
  };

  const reportSchema = {
    title: 'Advisory Council Directory Report',
    filters: [
      {
        title: 'Program',
        name: 'programId',
        type: 'dropdown',
        mode: 'multiple',
        params: [],
      },
    ],
    data: [
      {
        title: 'Full Data Set',
        charts: [
          {
            type: 'table',
            title: 'Table Title',
            width: 4,
            columns: [
              {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
              },
              {
                title: 'Council Associated With',
                dataIndex: 'councilAssociatedWith',
                key: 'councilAssociatedWith',
              },
              {
                title: 'Company',
                dataIndex: 'company',
                key: 'company',
              },
              {
                title: 'Job Title',
                dataIndex: 'jobTitle',
                key: 'jobTitle',
              },
              {
                title: 'Bio',
                dataIndex: 'bio',
                key: 'bio',
                // eslint-disable-next-line react/display-name
                render: (bio: string) => (
                  <>
                    <Popover content={<div dangerouslySetInnerHTML={{ __html: bio }} />} trigger="click">
                      <Button type="link">Bio</Button>
                    </Popover>
                  </>
                ),
              },
              {
                title: 'Headshot',
                dataIndex: 'headshot',
                key: 'headshot',
                render: (headshot: string) => {
                  if (!headshot) {
                    return 'N/A';
                  } else {
                    return (
                      <>
                        <Button type="link" onClick={() => window.open(headshot, '_blank')}>
                          Headshot
                        </Button>
                      </>
                    );
                  }
                },
              },
            ],
            rowKey: (record: any) => record.id,
            data: tableData,
          },
        ],
      },
    ],
  };

  const onTabChange = useCallback(() => {}, [reportSchema]); //eslint-disable-line

  const renderTabs = () => {
    const data = get(reportSchema, 'data', []);
    const tabDataSource = getTabDataSource(data);
    return <TabNavigation dataSource={tabDataSource} onTabChange={onTabChange} />;
  };

  const renderFilters = () => {
    return (
      <Container>
        <Form>
          <Row align="top" gutter={32}>
            <StyledCol span={8}>
              <FormItem label="Council Associated With">
                <StyledSelect>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    value={selectedCouncilAssociatedWith}
                    options={advisoryCouncils.map(({ id, name }: { id: string; name: string }) => ({
                      value: id,
                      label: name,
                    }))}
                    onChange={selectOnChange}
                  />
                </StyledSelect>
              </FormItem>
            </StyledCol>
          </Row>
        </Form>
        <Row justify="end" gutter={32} align="middle">
          <Col span={2}>
            <Button type="link" onClick={clearFilters}>
              Clear Filter
            </Button>
          </Col>
          <Col span={4}>
            <Button type="primary" onClick={applyFilters}>
              Apply Filter
            </Button>
          </Col>
        </Row>
      </Container>
    );
  };

  const renderContent = () => <ReportTab tabData={reportSchema.data[0]} />;

  return (
    <>
      <PageHeader
        title={reportSchema.title}
        breadcrumb={[
          <Link key="1" to="/">
            Home
          </Link>,
          <Link key="2" to="/reporting">
            Reporting
          </Link>,
          reportSchema.title,
        ]}
        extra={
          <>
            {renderFilters()}
            {renderTabs()}
          </>
        }
      />
      <CustomTabWrapper>
        <AdminContent type={AdminContentLayoutType.TABLE}>{renderContent()}</AdminContent>
      </CustomTabWrapper>
    </>
  );
}
